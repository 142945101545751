import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import "./utils/sentry";
import "./utils/i18n";
import "./utils/analytics";

import * as Sentry from "@sentry/react";

import { ChameleonProvider } from "@petsapp/chameleon";
import { AuthProvider } from "@petsapp/use-auth";
import { ApiProvider } from "@petsapp/use-api";
import { LocationProvider } from "@reach/router";
import { Reset } from "styled-reset";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createGlobalStyle } from "styled-components";
import { Spinner } from "./components/loading";
import { GlobalErrorUI } from "./components/error";
import { SessionProvider } from "./hooks/use-session";
import { onLocationChange } from "@petsapp/use-auth";

onLocationChange();

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box
  }

  html, body {
    font-size: 14px;
  }

  body, #root {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;

    font-family: ${(props) => props.theme.chameleon.typography.base.fontFamily};
  }
`;

type PreSelectedData = {
  groupId?: string;
  clinicId?: string;
  wp?: string; // wellness-plan
};

function getPreSelectedParams(): PreSelectedData {
  const preSelectedData: PreSelectedData = {};

  const params = new URLSearchParams(window.location.search);

  const groupId = params.get("groupId");
  const clinicId = params.get("clinicId");
  const preselectedClinicId = params.get("preselectedClinicId");
  const wp = params.get("wp");

  if (groupId != null) {
    preSelectedData.groupId = groupId;
  }

  if (clinicId != null) {
    preSelectedData.clinicId = clinicId;
  }

  if (preselectedClinicId != null) {
    preSelectedData.clinicId = preselectedClinicId;
  }

  if (wp != null) {
    preSelectedData.wp = "1";
  }

  return preSelectedData;
}

const appState = getPreSelectedParams();

ReactDOM.render(
  <React.StrictMode>
    <LocationProvider>
      <AuthProvider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        newDomain={process.env.REACT_APP_AUTH_DOMAIN!}
        clientID={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        audience={process.env.REACT_APP_AUTH0_API!}
        redirectUri={window.location.origin}
        appState={appState}
      >
        <ApiProvider
          domainMap={{
            1: `${process.env.REACT_APP_BASE_URL}/v1`,
            2: `${process.env.REACT_APP_BASE_URL}`,
          }}
        >
          <SessionProvider>
            <Reset />
            <ChameleonProvider>
              <Suspense fallback={<Spinner />}>
                <Sentry.ErrorBoundary fallback={() => <GlobalErrorUI />}>
                  <GlobalStyle />
                  <App />
                </Sentry.ErrorBoundary>
              </Suspense>
            </ChameleonProvider>
          </SessionProvider>
        </ApiProvider>
      </AuthProvider>
    </LocationProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
