import React, {
  Reducer,
  createContext,
  useReducer,
  useEffect,
  useMemo,
  useCallback,
  useContext,
} from "react";
import useApi from "@petsapp/use-api";
import useAuth, { IAuthContext } from "@petsapp/use-auth";
import usePushNotification, { WebToken } from "@petsapp/use-push-notification";
import {
  PetsAppAccount,
  Action as PetsAppAction,
  IdentityPet,
  Match,
} from "@petsapp/types";
import { SubscriptionSourcePayload } from "@petsapp/types/express/infrastructure/dao/subscription-source/subscription-source-types";
import Sentry from "../utils/sentry";
import { setUserId } from "@petsapp/use-analytics";
import { resetDeviceId, resetUserId } from "../utils/analytics";
import { omit } from "lodash";

type WPSource = Extract<
  SubscriptionSourcePayload,
  { type: "web_organic_signup" } | { type: "web_clinic_link_signup" }
>;

interface State {
  booting: boolean;
  nextStep:
    | null
    | "logged-out-payment-request"
    | "login"
    | "fetch-account"
    | "fetch-pets"
    | "fetch-matches"
    | "confirm-location"
    | "create-pet"
    | "check-notification-permissions"
    | "handle-pet-invite"
    | "request-notification-permissions"
    | "wellness-plans"
    | "logged-out-appointment-confirmation";
  account?: PetsAppAccount;
  actions?: PetsAppAction[];
  pets?: Array<IdentityPet>;
  wpSource?: WPSource;
  initialStep?:
    | "logged-out-payment-request"
    | "logged-out-appointment-confirmation"
    | null;
  initialParams: {
    matchGroup?: string;
    matchId?: string;
    clinicId?: string;
    groupId?: string;
    wp?: string;
    actionId?: string;
  };
}

interface Action<T> {
  type: T;
}

type ActionWithPayload<T, D> = Action<T> & { data: D };
type Actions =
  | Action<"authenticated">
  | ActionWithPayload<
      "set-initial-step",
      { initialStep: State["nextStep"]; booting: boolean }
    >
  | Action<"unauthenticated">
  | Action<"has-notification-permission">
  | Action<"needs-notification-permission">
  | Action<"skip-notification-permission">
  | Action<"reset">
  | Action<"remove-wellness-plans-initial-params">
  | ActionWithPayload<"location-confirmed", { account: PetsAppAccount }>
  | ActionWithPayload<
      "fetched-account",
      { account: PetsAppAccount; actions: PetsAppAction[] }
    >
  | ActionWithPayload<
      "handle-pet-invite",
      { account: PetsAppAccount; actions: PetsAppAction[] }
    >
  | ActionWithPayload<"fetched-pets", Array<IdentityPet>>
  | ActionWithPayload<"update-account", { account: PetsAppAccount }>
  | ActionWithPayload<"refreshed-pets", Array<IdentityPet>>
  | ActionWithPayload<"fetched-matches", Array<Match>>
  | ActionWithPayload<
      "set-initial-params",
      { clinicId?: string; groupId?: string }
    >
  | ActionWithPayload<"set-wp-source", { wpSource: WPSource }>
  | Action<"remove-wp-source">;

function getActions(actions: PetsAppAction[]) {
  return actions.filter((action) =>
    ["add-pet-to-clinic", "add-owner-and-pets-to-clinic"].includes(action.type),
  );
}

const isInWellnessPlanFlow = (acc?: PetsAppAccount) => {
  return Boolean(
    acc?.joinedByWellnessPlan && !acc?.hasFinishedWellnessPlanFlow,
  );
};

const getNextStep = (acc?: PetsAppAccount) => {
  if (isInWellnessPlanFlow(acc)) {
    return "wellness-plans";
  }

  if (acc?.lat && acc?.lng) {
    return "check-notification-permissions";
  }

  return "confirm-location";
};

const reducer: Reducer<State, Actions> = (prevState, action) => {
  if (action.type === "authenticated") {
    return {
      ...prevState,
      nextStep: "fetch-account",
    };
  }

  if (action.type === "set-initial-step") {
    return {
      ...prevState,
      nextStep: action.data.initialStep,
      booting: action.data.booting,
    };
  }

  if (action.type === "unauthenticated") {
    return {
      ...prevState,
      booting: false,
      nextStep: "login",
    };
  }

  if (action.type === "update-account") {
    return {
      ...prevState,
      account: action.data.account,
    };
  }

  if (action.type === "fetched-account") {
    const { account } = action.data;

    const wellnessPlanFlowActive = isInWellnessPlanFlow(account);

    const nextStep =
      wellnessPlanFlowActive || (account.lat && account.lng)
        ? "fetch-pets"
        : "confirm-location";

    const booting = nextStep === "fetch-pets";

    return {
      ...prevState,
      booting,
      account: action.data.account,
      actions: getActions(action.data.actions),
      nextStep,
    };
  }

  if (action.type === "location-confirmed") {
    return {
      ...prevState,
      account: action.data.account,
      nextStep: "fetch-pets",
    };
  }

  if (action.type === "handle-pet-invite") {
    return {
      ...prevState,
      account: action.data.account,
      actions: getActions(action.data.actions),
      nextStep: "fetch-pets",
    };
  }

  if (action.type === "has-notification-permission") {
    return {
      ...prevState,
      booting: false,
      nextStep: null,
    };
  }

  if (action.type === "needs-notification-permission") {
    return {
      ...prevState,
      booting: false,
      nextStep: "request-notification-permissions",
    };
  }

  if (action.type === "skip-notification-permission") {
    return {
      ...prevState,
      booting: false,
      nextStep: null,
    };
  }

  if (action.type === "refreshed-pets") {
    return {
      ...prevState,
      pets: action.data,
    };
  }

  if (action.type === "fetched-pets") {
    const nextStep = getNextStep(prevState?.account);
    const isInWellnessPlanFlow = nextStep === "wellness-plans";

    if (action.data.length === 0) {
      return {
        ...prevState,
        booting: false,
        nextStep: isInWellnessPlanFlow ? "wellness-plans" : "create-pet",
      };
    }

    return {
      ...prevState,
      pets: action.data,
      booting: false,
      nextStep,
    };
  }

  if (action.type === "set-initial-params") {
    return {
      ...prevState,
      initialParams: {
        ...prevState.initialParams,
        ...action.data,
      },
    };
  }

  if (action.type === "set-wp-source") {
    return {
      ...prevState,
      wpSource: action.data.wpSource,
    };
  }

  if (action.type === "remove-wp-source") {
    return omit(prevState, ["wpSource"]);
  }

  if (action.type === "remove-wellness-plans-initial-params") {
    return {
      ...prevState,
      initialParams: omit(prevState.initialParams, [
        "wp",
        "groupId",
        "clinicId",
      ]),
    };
  }

  if (action.type === "reset") {
    return initState();
  }

  return prevState;
};

const getInitialParams = () => {
  const params = new URLSearchParams(window.location.search);

  return {
    actionId:
      params.get("actionId") ||
      window.localStorage.getItem("actionId") ||
      undefined,
    clinicId:
      params.get("preSelectedClinic") ||
      params.get("clinicId") ||
      window.localStorage.getItem("clinicId") ||
      undefined,
    groupId:
      params.get("preSelectedGroupId") ||
      params.get("groupId") ||
      window.localStorage.getItem("groupId") ||
      undefined,
    wp: params.get("wp") || window.localStorage.getItem("wp") || undefined,
  };
};

const getInitialStep = () => {
  const { pathname } = window.location;

  if (pathname === "/pay") {
    return {
      initialStep: "logged-out-payment-request" as const,
      booting: false,
    };
  }

  if (pathname === "/appointment-confirmation") {
    return {
      initialStep: "logged-out-appointment-confirmation" as const,
      booting: false,
    };
  }

  return null;
};

const initState = (): State => {
  const { booting = true, initialStep } = getInitialStep() ?? {};
  const params = getInitialParams();
  return {
    booting,
    nextStep: initialStep ?? null,
    initialParams: params,
    wpSource:
      params.wp != null
        ? {
            type: "web_clinic_link_signup",
          }
        : undefined,
  };
};

export const SessionProvider: React.FC = ({ children }) => {
  const {
    hasPermission,
    hasCapability,
    register,
    install,
  } = usePushNotification();

  const [state, dispatch] = useReducer(reducer, {}, initState);

  useEffect(() => {
    const { clinicId, groupId, wp, actionId } = state.initialParams;

    if (actionId) {
      window.localStorage.setItem("actionId", actionId);
    }

    if (groupId && wp) {
      window.localStorage.setItem("groupId", groupId);
      window.localStorage.setItem("wp", wp);
    }

    if (clinicId) {
      window.localStorage.setItem("clinicId", clinicId);
    }
  }, [state.initialParams]);

  const { nextStep } = state;
  const auth = useAuth();
  const { get, patch } = useApi();

  const initSetup = useCallback(() => {
    if (auth.state === "authenticated") {
      dispatch({ type: "authenticated" });
    } else if (auth.state === "unauthenticated") {
      dispatch({ type: "unauthenticated" });
    }
  }, [auth.state]);

  const refresh = useCallback(() => {
    dispatch({
      type: "reset",
    });

    initSetup();
  }, [initSetup]);

  const updateAddressDetails = useCallback(
    async function updateAddressDetails({
      lat,
      lng,
      postcode,
      city,
      stateOrRegion,
      countryCode,
    }: {
      lat: number;
      lng: number;
      postcode: string;
      city: string;
      stateOrRegion: string;
      countryCode: string;
    }) {
      const res = await patch(
        "/account/mine",
        "NONE",
        JSON.stringify({
          updates: [
            {
              type: "account/update-address-details",
              payload: { lat, lng, postcode, city, stateOrRegion, countryCode },
            },
          ],
        }),
        1,
      );

      if (res.ok) {
        const { account }: { account: PetsAppAccount } = await res.json();
        dispatch({ type: "location-confirmed", data: { account } });
      } else {
        throw new Error(await res.json());
      }
    },
    [patch],
  );

  const updateNotificationToken = useCallback(
    async function updateNotificationToken(token?: WebToken) {
      if (!token) {
        dispatch({ type: "has-notification-permission" });
        return;
      }

      const res = await patch(
        "/account/mine",
        "NONE",
        JSON.stringify({
          updates: [
            {
              type: "account/update-device-token",
              payload: token,
            },
          ],
        }),
        1,
      );

      if (res.ok) {
        dispatch({ type: "has-notification-permission" });
      } else {
        throw new Error(await res.json());
      }
    },
    [patch],
  );

  const fetchPets = useCallback(
    async function fetchPets() {
      const res = await get("/identity/mine", "NONE", 1);

      if (res.ok) {
        const out: { identities: Array<IdentityPet> } = await res.json();

        dispatch({
          type: "fetched-pets",
          data: out.identities.filter(
            (identity: IdentityPet) => identity.type === "pet",
          ),
        });
      } else {
        throw new Error(JSON.stringify(await res.json()));
      }
    },
    [dispatch, get],
  );

  useEffect(() => {
    if (state.nextStep == null && state.booting === true) {
      initSetup();
    }
  }, [initSetup, state.nextStep, state.booting]);

  useEffect(() => {
    if (auth.extraData != null) {
      const data: Record<string, string> = {};

      const { groupId, clinicId, wp } = auth.extraData;

      if (groupId != null) {
        data.groupId = groupId;
      }

      if (clinicId != null) {
        data.clinicId = clinicId;
      }

      if (wp != null) {
        data.wp = "1";
      }

      dispatch({ type: "set-initial-params", data });
    }
  }, [auth.extraData]);

  const updateAccount = useCallback(
    ({ account }: { account: PetsAppAccount }) => {
      dispatch({ type: "update-account", data: { account } });
    },
    [],
  );

  const fetchAccount = useCallback(async () => {
    try {
      let res = await get("/account/mine", "NONE", 1);
      let data: { account: PetsAppAccount; actions: PetsAppAction[] };
      let initialActions: PetsAppAction[] = [];

      // handle action from url when email is different than in invitation
      if (state.initialParams.actionId) {
        try {
          const initialActionResponse = await get(
            `/public/action/${state.initialParams.actionId}`,
            "NONE",
            1,
          );
          const actionFromResponse: PetsAppAction = await initialActionResponse.json();
          if (actionFromResponse.state === "runnable") {
            initialActions = [actionFromResponse];
          }
        } catch (err) {
          Sentry.captureException(err);
        } finally {
          window.localStorage.removeItem("actionId");
        }
      }

      if (res.ok) {
        const { account, actions = [] } = await res.json();

        data = { account, actions };
        setUserId(account.accountId);
      } else {
        const createRes = await patch(
          "/account/mine",
          "NONE",
          JSON.stringify({
            updates: [
              {
                type: "account/create",
                payload: {
                  joinedByWellnessPlan: Boolean(state.initialParams.wp),
                },
              },
            ],
          }),
          1,
        );

        if (createRes.ok) {
          const { account, actions = [] } = await createRes.json();
          data = {
            account,
            actions,
          };

          setUserId(account.accountId);
        } else {
          throw new Error(JSON.stringify(await createRes.json()));
        }
      }

      data.actions = [...initialActions, ...data.actions];

      dispatch({ type: "fetched-account", data });
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [get, patch, state.initialParams.actionId, state.initialParams.wp]);

  useEffect(() => {
    async function setup() {
      if (nextStep === "fetch-account") {
        await fetchAccount();
      }

      if (nextStep === "fetch-pets") {
        await fetchPets();
      }

      if (nextStep === "check-notification-permissions") {
        if (hasPermission()) {
          const token = await register();

          await updateNotificationToken(token);
        } else if (hasCapability()) {
          await install();
          dispatch({ type: "needs-notification-permission" });
        } else {
          dispatch({ type: "skip-notification-permission" });
        }
      }
    }

    setup();
  }, [
    fetchAccount,
    fetchPets,
    hasCapability,
    hasPermission,
    install,
    nextStep,
    register,
    updateNotificationToken,
  ]);

  const logout = useCallback(() => {
    resetDeviceId();
    resetUserId();
    auth.logout();
  }, [auth]);

  const hasFeature = useCallback(
    ({ feature, identityId }) => {
      if (!state.pets || state.pets.length === 0) {
        return false;
      }

      const identityToCheck = state.pets.find(
        (identity) => identity.identityId === identityId,
      );

      if (!identityToCheck) {
        return false;
      }

      const identityFeature = identityToCheck.features?.find(
        ({ feature: identityFeature }) => identityFeature === feature,
      );

      return !!identityFeature && !!identityFeature.on;
    },
    [state.pets],
  );

  const resetWellnessPlansInitialParams = useCallback(() => {
    dispatch({ type: "remove-wellness-plans-initial-params" });
    window.localStorage.removeItem("clinicId");
    window.localStorage.removeItem("groupId");
    window.localStorage.removeItem("wp");
  }, []);

  const addWpSource = useCallback((source: WPSource) => {
    dispatch({
      type: "set-wp-source",
      data: { wpSource: source },
    });
  }, []);

  const removeWpSource = useCallback(() => {
    dispatch({
      type: "remove-wp-source",
    });
  }, []);

  const value: ISessionContext = useMemo(() => {
    return {
      ...auth,
      logout,
      refresh,
      initialParams: state.initialParams,
      fetchPets,
      fetchAccount,
      updateAccount,
      updateAddressDetails,
      updateNotificationToken,
      account: state.account,
      actions: state.actions,
      booting: state.booting,
      nextStep: state.nextStep,
      addWpSource,
      removeWpSource,
      pets: state.pets,
      isInWellnessPlanFlow: isInWellnessPlanFlow(state.account),
      hasFeature,
      resetWellnessPlansInitialParams,
      wpSource: state.wpSource,
    };
  }, [
    auth,
    refresh,
    logout,
    fetchPets,
    fetchAccount,
    updateAccount,
    updateAddressDetails,
    updateNotificationToken,
    hasFeature,
    resetWellnessPlansInitialParams,
    addWpSource,
    removeWpSource,
    state,
  ]);

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

interface ISessionContext {
  state: IAuthContext["state"];
  updateAddressDetails(props: {
    lat: number;
    lng: number;
    postcode: string;
    city: string;
    stateOrRegion: string;
    countryCode: string;
  }): void;
  refresh(): void;
  fetchAccount(): void;
  fetchPets(): void;
  updateAccount({ account }: { account: PetsAppAccount }): void;
  getToken(): void;
  loginEmail(details: { email: string; password: string }): void;
  loginFacebook(): void;
  loginGoogle(): void;
  logout(): void;
  updateNotificationToken: (web?: WebToken) => void;
  register(params: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }): void;
  actions?: PetsAppAction[];
  pets?: State["pets"];
  account?: State["account"];
  booting: State["booting"];
  nextStep: string | null;
  initialParams: { clinicId?: string; groupId?: string; wp?: string };
  isInWellnessPlanFlow: boolean;
  wpSource?: WPSource;
  addWpSource(source: WPSource): void;
  removeWpSource(): void;
  hasFeature: ({
    feature,
    identityId,
  }: {
    feature: string;
    identityId: string;
  }) => boolean;
  resetWellnessPlansInitialParams: () => void;
}

const SessionContext = createContext<ISessionContext>({
  hasFeature: () => false,
  state: "unauthenticated",
  getToken() {
    return Promise.resolve("");
  },
  addWpSource() {},
  removeWpSource() {},
  updateAddressDetails() {},
  updateNotificationToken() {},
  loginEmail() {
    return Promise.resolve();
  },
  loginFacebook() {
    return Promise.resolve();
  },
  loginGoogle() {
    return Promise.resolve();
  },
  logout() {},
  register() {
    return Promise.resolve();
  },
  fetchAccount() {},
  updateAccount() {},
  fetchPets() {
    return [];
  },
  refresh() {
    return Promise.resolve();
  },
  actions: [],
  nextStep: null,
  booting: true,
  initialParams: {},
  isInWellnessPlanFlow: false,

  resetWellnessPlansInitialParams: () => {},
});

const useSession = () => {
  const context = useContext(SessionContext);

  if (!context) {
    throw new Error("useSession must be used within a SessionProvider");
  }

  return context;
};

export default useSession;
