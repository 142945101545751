import React, { useEffect, useState } from "react";
import {
  navigate,
  RouteComponentProps,
  Router,
  useLocation,
  useMatch,
  useNavigate,
} from "@reach/router";
import { IdentityPet } from "@petsapp/types";
import * as Sentry from "@sentry/react";

import useSession from "./hooks/use-session";
import { IdentityProvider } from "./hooks/use-identity-context";
import { Spinner } from "./components/loading";
import SubscriptionSuccess from "./screens/wellness-plans/subscription-success";
import { useShouldRenderWellnessPlanSuccessPage } from "./hooks/use-should-render-wellness-plan-success-page";
import { log } from "./utils/logger";
import { LoggedOutPaymentRequest } from "./screens/logged-out-payment-request";
import { DeliveryAddressForm } from "./screens/profile/delivery-address-form";
import { LoggedOutAppointmentConfirmation } from "./screens/logged-out-appointment-confirmation";

const WellnessPlans = React.lazy(() => import("./screens/wellness-plans"));
const InvitePets = React.lazy(
  () => import("./screens/invite/invite-controller"),
);
const Profile = React.lazy(() => import("./screens/profile/profile"));
const CreatePet = React.lazy(() => import("./screens/create-pet/create-pet"));
const ConfirmLocation = React.lazy(() => import("./screens/confirm-location"));
const RequestNotifications = React.lazy(
  () => import("./screens/notifications/request-notifications"),
);
const AuthController = React.lazy(
  () => import("./screens/authentication/auth-controller"),
);
const Pet = React.lazy(() => import("./screens/pet/pet-controller"));
const EditPet = React.lazy(() => import("./screens/pet/edit-pet-controller"));
const Match = React.lazy(() => import("./screens/match/match-controller"));
const Matches = React.lazy(
  () => import("./screens/matches/matches-controller"),
);
const ChoosePet = React.lazy(
  () => import("./screens/wellness-plans/choose-pet"),
);
const PaymentChangeSuccess = React.lazy(
  () => import("./screens/wellness-plans/payment-change-success"),
);
const Checkout = React.lazy(() => import("./screens/checkout/index"));

const Main = ({
  identities,
  refresh,
}: {
  identities: IdentityPet[];
  refresh(): void;
}) => {
  const [initialIdentityId, setInitialIdentityId] = useState<string>();
  const location = useLocation();
  const { account, initialParams, pets } = useSession();
  const navigate = useNavigate();

  useEffect(() => {
    if (!initialIdentityId) {
      // If we are landing on a pet profile or match we need to attach
      // the identity to the identity provider so we can attach the
      // correct identityId to the x-identity header.
      const match = location.pathname.match(/pets|chat\/(.*)/);

      if (match !== null && match[1]) {
        const [, path] = match;
        setInitialIdentityId(path.substr(0, 36));
      } else {
        setInitialIdentityId(identities[0].identityId);
      }
    }
  }, [identities, initialIdentityId, location.pathname]);

  useEffect(() => {
    Sentry.setContext("identity", identities[0]);
    account && Sentry.setContext("account", account);
  }, [identities, account]);

  useEffect(() => {
    if (!initialIdentityId) {
      return;
    }

    if (initialParams.wp !== undefined) {
      if (pets?.length === 1) {
        navigate(`/wp/${pets[0].identityId}/plans/choose-plan`);
      } else {
        navigate("/choose-pet-for-wp");
      }
    }
  }, [initialParams.wp, pets, navigate, initialIdentityId]);

  if (!initialIdentityId) {
    return <Spinner />;
  }

  return (
    <IdentityProvider
      identities={identities}
      initialIdentityId={initialIdentityId}
    >
      <Router primary style={{ display: "flex", height: "100%" }}>
        <RedirectTo default to="/chat" />
        <Pet path="/pets/:id" />
        <CreatePet
          back="/account"
          path="/create"
          refresh={async (id) => {
            await refresh();

            navigate(`/pets/${id}`);
          }}
        />
        <DeliveryAddressForm path="/delivery-address" />
        <EditPet path="/pets/:id/edit" />
        <Matches path="/chat" />
        <Match path="/chat/:matchGroup" />
        <Profile path="/account" />
        <WellnessPlans path="/wp/:petIdentityId/*" />
        <ChoosePet path="/choose-pet-for-wp" />
      </Router>
    </IdentityProvider>
  );
};

interface RedirectProps extends RouteComponentProps {
  to: string;
}

function RedirectTo(props: RedirectProps) {
  useEffect(() => {
    props.navigate && props.navigate(props.to);
  }, [props]);

  return null;
}

function App() {
  const session = useSession();
  const {
    shouldRenderSuccessPage,
    onClose,
  } = useShouldRenderWellnessPlanSuccessPage();
  const { pathname } = useLocation();
  const checkoutMatch = useMatch("/checkout/:id");

  log(session);

  if (pathname === "/payment-change-success") {
    // this view will let user click "Done" to open main path "/"
    return <PaymentChangeSuccess />;
  }

  if (pathname === "/reset-successful") {
    return <AuthController isResetPassword />;
  }

  if (checkoutMatch != null) {
    return <Checkout id={checkoutMatch.id} />;
  }

  if (session.booting) {
    return <Spinner />;
  }

  if (session.nextStep === "login") {
    return <AuthController />;
  }

  if (session.nextStep === "logged-out-payment-request") {
    return <LoggedOutPaymentRequest />;
  }

  if (session.nextStep === "logged-out-appointment-confirmation") {
    return <LoggedOutAppointmentConfirmation />;
  }

  if (session.actions != null && session.actions?.length > 0) {
    return (
      <InvitePets
        actions={session.actions}
        refresh={async () => {
          await session.fetchAccount();
          navigate("/");
        }}
      />
    );
  }

  if (shouldRenderSuccessPage) {
    return (
      <Router>
        <RedirectTo default to="/plans/subscription-success" />
        <SubscriptionSuccess
          onClose={onClose}
          path="/plans/subscription-success"
        />
      </Router>
    );
  }

  if (session.nextStep === "confirm-location") {
    return (
      <Router primary style={{ display: "flex", height: "100%" }}>
        <ConfirmLocation path="/location" refresh={session.fetchPets} />
        <RedirectTo default to="/location" />
        <Profile path="/account" />
      </Router>
    );
  }

  if (session.nextStep === "wellness-plans") {
    return <WellnessPlans />;
  }

  if (session.nextStep === "create-pet") {
    return (
      <Router primary style={{ display: "flex", height: "100%" }}>
        <RedirectTo default to="/create" />
        <Profile path="/account" />
        <CreatePet
          path="/create"
          groupId={session.initialParams.groupId}
          clinicId={session.initialParams.clinicId}
          refresh={async () => {
            await session.fetchPets();
            // we need to wait for next router to render
            navigate("/");
          }}
        />
      </Router>
    );
  }

  if (session.nextStep === "request-notification-permissions") {
    return (
      <RequestNotifications
        updateNotificationToken={session.updateNotificationToken}
      />
    );
  }

  if (session.nextStep === null) {
    return <Main identities={session.pets!} refresh={session.fetchPets} />;
  }

  return null;
}

export default App;
